
import { defineComponent, ref } from "vue";
import { Form, Field, ErrorMessage } from "vee-validate";
import ApiService from "@/core/services/ApiService";
import * as Yup from "yup";
import variables from "@/router/api";

export default defineComponent({
  name: "report-claim-modal",
  components: { Form, Field, ErrorMessage },
  data() {
    return {
      displayRegField: false,
      policyType: "",
      emailAddress: "",
      registrationNumber: "",
      descriptionOfIncident: "",
    };
  },
  setup() {
    const submitButton1 = ref<HTMLElement | null>(null);

    const validateForm = Yup.object().shape({
      policyType: Yup.string()
        .required()
        .oneOf(["MOT", "HOU"])
        .label("Policy type"),
      emailAddress: Yup.string()
        .required()
        .label("Email address"),
      registrationNumber: Yup.string()
        .notRequired()
        .label("Registration number"),
      descriptionOfIncident: Yup.string()
        .required()
        .label("Description of incident"),
    });

    const sendCarInsuranceQuote = (values, { resetForm }) => {
      if (values["policyType"] == "MOT" && values["registrationNumber"] === "") {
        return variables.toastAlert("Registration number is required.", "error");
      }

      if (submitButton1.value) {
        // Activate indicator
        submitButton1.value.setAttribute("data-kt-indicator", "on");

        setTimeout(() => {
          submitButton1.value?.removeAttribute("data-kt-indicator");

          // Send form data
          const formData = new FormData();

          formData.append("policyType", values["policyType"]);
          formData.append("emailAddress", values["emailAddress"]);
          formData.append("registrationNumber", values["registrationNumber"]);
          formData.append(
            "descriptionOfIncident",
            values["descriptionOfIncident"]
          );

          ApiService.post("unauthenticated-claim", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
            .then((response) => {
              resetForm();
              variables.toastAlert(response.data.data.message, "success");
            })
            .catch(function(error) {
              variables.toastAlert(error.response.data.error, "error");
            });
        }, 2000);
      }
    };

    return {
      submitButton1,
      sendCarInsuranceQuote,
      validateForm,
    };
  },
  methods: {
    getPolicyType(event) {
      const value = event.target.value;

      if (value === "MOT") {
        this.displayRegField = true;
      } else {
        this.displayRegField = false;
        this.registrationNumber = "";
      }
    },
  },
});
