
import { defineComponent } from "vue";
import { SocialChat } from "vue-social-chat";

export default defineComponent({
  name: "WhatsAppWidget",
  components: {
    SocialChat,
  },
  setup() {
    const attendants = [
      // {
      //   app: "whatsapp",
      //   label: "Insurance Advisor",
      //   name: "Judith",
      //   number: "2348023980416",
      //   avatar: {
      //     src: "/media/landing/judith.jpg",
      //     alt: "Judith",
      //   },
      // },
      {
        app: "whatsapp",
        label: "Technical support",
        name: "Akinpelumi",
        number: "2347032759228",
        avatar: {
          src: "/media/landing/pelumi.jpg",
          alt: "Akinpelumi",
        },
      },
      {
        app: "whatsapp",
        label: "Technical support",
        name: "Adedolapo",
        number: "2348034444134",
        avatar: {
          src: "/media/landing/dolapo.jpg",
          alt: "Adedolapo",
        },
      },
    ];

    return { attendants };
  },
});
