
import { defineComponent, ref } from "vue";
import { Form, Field, ErrorMessage } from "vee-validate";
import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import * as Yup from "yup";
import variables from "@/router/api";

export default defineComponent({
  name: "house-insurance-form-component",
  components: { Form, Field, ErrorMessage },
  data() {
    return {
      fullName: "",
      emailAddress: "",
      phoneNumber: "",
      estimatedValue: "",
      ownershipStatus: "",
    };
  },
  setup() {
    const submitButton1 = ref<HTMLElement | null>(null);

    const validateForm = Yup.object().shape({
      fullName: Yup.string()
        .required()
        .label("Full name"),
      emailAddress: Yup.string()
        .required()
        .label("Email address"),
      phoneNumber: Yup.string()
        .required()
        .label("Phone number"),
      ownershipStatus: Yup.string()
        .oneOf(["Owned", "Rented"])
        .required()
        .label("Type"),
      estimatedValue: Yup.string()
        .required()
        .label("Estimated value"),
    });

    const sendHouseInsuranceQuote = (values, { resetForm }) => {
      if (submitButton1.value) {
        // Activate indicator
        submitButton1.value.setAttribute("data-kt-indicator", "on");

        setTimeout(() => {
          submitButton1.value?.removeAttribute("data-kt-indicator");

          // Send form data
          const formData = new FormData();

          formData.append("fullName", values["fullName"]);
          formData.append("emailAddress", values["emailAddress"]);
          formData.append("phoneNumber", values["phoneNumber"]);
          formData.append("estimatedValue", values["estimatedValue"]);
          formData.append("ownershipStatus", values["ownershipStatus"]);
          formData.append("insuranceType", "house");
          // return new Response(formData).text().then(console.log);

          ApiService.post(variables.QUOTE_ROUTE, formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
            .then((response) => {
              resetForm();
              variables.toastAlert(response.data.message, "success");
            })
            .catch(function(error) {
              variables.toastAlert(error.response.data.error, "error");
            });
        }, 2000);
      }
    };

    return {
      submitButton1,
      sendHouseInsuranceQuote,
      validateForm,
    };
  },
  methods: {
    acceptNumberOnly(event) {
      variables.acceptNumberOnly(event);
    },
    premium(event) {
      const newValue = event.target.value
        .replace(/\D/g, "")
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      this.$nextTick(() => (event.target.value = newValue));
    },
  },
});
