
import { defineComponent } from "vue";
import LifeCovers from "@/views/frontend/constants/life-covers.js";

export default defineComponent({
  name: "life-insurance-terms",
  components: {},
  data() {
    return {
      LifeCovers: LifeCovers,
    };
  },
});
