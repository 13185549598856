import variables from "@/router/api";

const covers = [
  {
    id: variables.generateRandomNumber(),
    name: "Benefits",
    column1: null,
    column2: null,
    column3: null,
    header: true,
  },
  {
    id: variables.generateRandomNumber(),
    name: "General/Specialist Consultations",
    column1: true,
    column2: true,
    column3: true,
    header: false,
  },
  {
    id: variables.generateRandomNumber(),
    name: "Out-Patient Services & In-Patient Services",
    column1: true,
    column2: true,
    column3: true,
    header: false,
  },
  {
    id: variables.generateRandomNumber(),
    name: "Prescribed Drugs/Infusions",
    column1: true,
    column2: true,
    column3: true,
    header: false,
  },
  {
    id: variables.generateRandomNumber(),
    name: "Laboratory Investigations",
    column1: true,
    column2: true,
    column3: true,
    header: false,
  },
  {
    id: variables.generateRandomNumber(),
    name: "Ultrasounds Scans/Plain & Contrast X-Rays",
    column1: true,
    column2: true,
    column3: true,
    header: false,
  },
  {
    id: variables.generateRandomNumber(),
    name: "Emergency Evacuation Services (Local & International)",
    column1: true,
    column2: true,
    column3: true,
    header: false,
  },
  {
    id: variables.generateRandomNumber(),
    name: "Worldwide Health Benefits(Including USA & Canada)",
    column1: false,
    column2: false,
    column3: true,
    header: false,
  },
  {
    id: variables.generateRandomNumber(),
    name: "Worldwide Health Benefits(But Excluding USA & Canada)",
    column1: false,
    column2: true,
    column3: false,
    header: false,
  },
  {
    id: variables.generateRandomNumber(),
    name: "African Cover(Including Anywhere In Nigeria)",
    column1: true,
    column2: false,
    column3: false,
    header: false,
  },
  {
    id: variables.generateRandomNumber(),
    name: "Maternity Benefits And Childbirth",
    column1: true,
    column2: true,
    column3: true,
    header: false,
  },
  {
    id: variables.generateRandomNumber(),
    name: "Health Checks/Examinations",
    column1: true,
    column2: true,
    column3: true,
    header: false,
  },
  {
    id: variables.generateRandomNumber(),
    name: "Family Planning Services/Counselling",
    column1: true,
    column2: true,
    column3: true,
    header: false,
  },
  {
    id: variables.generateRandomNumber(),
    name: "Routine(NPI) Immunizations",
    column1: true,
    column2: true,
    column3: true,
    header: false,
  },
  {
    id: variables.generateRandomNumber(),
    name: "Emergency Care/Ambulance Services",
    column1: true,
    column2: true,
    column3: true,
    header: false,
  },
  {
    id: variables.generateRandomNumber(),
    name: "Surgeries(Major, Intermediate & Minor Surgeries)",
    column1: true,
    column2: true,
    column3: true,
    header: false,
  },
  {
    id: variables.generateRandomNumber(),
    name: "Optical Services/Cares",
    column1: true,
    column2: true,
    column3: true,
    header: false,
  },
  {
    id: variables.generateRandomNumber(),
    name: "Dental Treatment/Cares",
    column1: true,
    column2: true,
    column3: true,
    header: false,
  },
  {
    id: variables.generateRandomNumber(),
    name: "HIV/Aids Treatment",
    column1: true,
    column2: true,
    column3: true,
    header: false,
  },
  {
    id: variables.generateRandomNumber(),
    name: "Cancer Treatment/Care",
    column1: true,
    column2: true,
    column3: true,
    header: false,
  },
  {
    id: variables.generateRandomNumber(),
    name: "Psychiatry Services",
    column1: true,
    column2: true,
    column3: true,
    header: false,
  },
  {
    id: variables.generateRandomNumber(),
    name: "Transplant Surgery",
    column1: true,
    column2: true,
    column3: true,
    header: false,
  },
  {
    id: variables.generateRandomNumber(),
    name: "Home Care And Domiciliary Services",
    column1: true,
    column2: true,
    column3: true,
    header: false,
  },
  {
    id: variables.generateRandomNumber(),
    name: "Joint Replacements And Prosthetic Limbs",
    column1: true,
    column2: true,
    column3: true,
    header: false,
  },
  {
    id: variables.generateRandomNumber(),
    name: "Extensions",
    column1: null,
    column2: null,
    column3: null,
    header: true,
  },
  {
    id: variables.generateRandomNumber(),
    name: "Maternity Expenses Cover",
    column1: true,
    column2: true,
    column3: true,
    header: false,
  },
  {
    id: variables.generateRandomNumber(),
    name: "High-Tech Hospitals Cover",
    column1: true,
    column2: true,
    column3: true,
    header: false,
  },
  {
    id: variables.generateRandomNumber(),
    name: "Private Psychiatric",
    column1: true,
    column2: true,
    column3: true,
    header: false,
  },
  {
    id: variables.generateRandomNumber(),
    name: "Convalescence Or Travel Cover",
    column1: true,
    column2: true,
    column3: true,
    header: false,
  },
  {
    id: variables.generateRandomNumber(),
    name: "Transplant Surgery For Treatment Related)",
    column1: true,
    column2: true,
    column3: true,
    header: false,
  },
  {
    id: variables.generateRandomNumber(),
    name: "Home Care And Domiciliary Services",
    column1: true,
    column2: true,
    column3: true,
    header: false,
  },
  {
    id: variables.generateRandomNumber(),
    name: "Joint Replacements And Prosthetic Limbs",
    column1: true,
    column2: true,
    column3: true,
    header: false,
  },
  {
    id: variables.generateRandomNumber(),
    name: "Exclusions",
    column1: null,
    column2: null,
    column3: null,
    header: true,
  },
  {
    id: variables.generateRandomNumber(),
    name: "Death",
    column1: true,
    column2: true,
    column3: true,
    header: false,
  },
  {
    id: variables.generateRandomNumber(),
    name: "Undeclared Pre-Existing Medical Conditions",
    column1: true,
    column2: true,
    column3: true,
    header: false,
  },
  {
    id: variables.generateRandomNumber(),
    name: "Plastic/Cosmetic Surgeries",
    column1: true,
    column2: true,
    column3: true,
    header: false,
  },
  {
    id: variables.generateRandomNumber(),
    name:
      "Treatment Of Infertility, Artificial Insemination And Invitro Fertilization",
    column1: true,
    column2: true,
    column3: true,
    header: false,
  },
  {
    id: variables.generateRandomNumber(),
    name: "All Congenital Abnormalities And Birth Defects",
    column1: true,
    column2: true,
    column3: true,
    header: false,
  },
  {
    id: variables.generateRandomNumber(),
    name: "Virility Enhancing Drugs",
    column1: true,
    column2: true,
    column3: true,
    header: false,
  },
  {
    id: variables.generateRandomNumber(),
    name:
      "Herbal Drugs, Non-Prescription Drugs, Food Supplements And Experimental Drugs And Treatment",
    column1: true,
    column2: true,
    column3: true,
    header: false,
  },
  {
    id: variables.generateRandomNumber(),
    name: "Long Terms Psychiatric Illnesses (Longer Than 6months)",
    column1: true,
    column2: true,
    column3: true,
    header: false,
  },
  {
    id: variables.generateRandomNumber(),
    name: "Self-Inflicted Injuries",
    column1: true,
    column2: true,
    column3: true,
    header: false,
  },
  {
    id: variables.generateRandomNumber(),
    name: "Treatment Of Obesity",
    column1: true,
    column2: true,
    column3: true,
    header: false,
  },
  {
    id: variables.generateRandomNumber(),
    name: "Conditions",
    column1: null,
    column2: null,
    column3: null,
    header: true,
  },
  {
    id: variables.generateRandomNumber(),
    name: "Full Declaration Of Medical Conditions",
    column1: true,
    column2: true,
    column3: true,
    header: false,
  },
  {
    id: variables.generateRandomNumber(),
    name: "Full Declaration Of Social Pass-Times And Habits",
    column1: true,
    column2: true,
    column3: true,
    header: false,
  },
];

export default covers;
