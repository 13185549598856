
import { defineComponent, onMounted, ref } from "vue";
import KTScrollTop from "@/layout/extras/ScrollTop.vue";
import WhatsAppWidget from "@/layout/extras/WhatsAppWidget.vue";
import CarInsuranceForm from "@/views/frontend/forms/CarInsurance.vue";
import HouseInsuranceForm from "@/views/frontend/forms/HouseInsurance.vue";
import LifeInsuranceForm from "@/views/frontend/forms/LifeInsurance.vue";
import PersonalEffectForm from "@/views/frontend/forms/PersonalEffect.vue";
import TradeInsuranceForm from "@/views/frontend/forms/TradeInsurance.vue";
import TravelInsuranceForm from "@/views/frontend/forms/TravelInsurance.vue";
import HealthInsuranceForm from "@/views/frontend/forms/HealthInsurance.vue";
import FuneralInsuranceForm from "@/views/frontend/forms/FuneralInsurance.vue";
import MotorInsuranceTerms from "@/views/frontend/modals/MotorInsuranceTerms.vue";
import HouseInsuranceTerms from "@/views/frontend/modals/HouseInsuranceTerms.vue";
import FuneralInsuranceTerms from "@/views/frontend/modals/FuneralInsuranceTerms.vue";
import HealthInsuranceTerms from "@/views/frontend/modals/HealthInsuranceTerms.vue";
import LifeInsuranceTerms from "@/views/frontend/modals/LifeInsuranceTerms.vue";
import ReportClaim from "@/views/frontend/modals/ReportClaim.vue";
import Typed from "typed.js";

export default defineComponent({
  name: "landing-page-component",
  components: {
    KTScrollTop,
    WhatsAppWidget,
    CarInsuranceForm,
    HouseInsuranceForm,
    LifeInsuranceForm,
    PersonalEffectForm,
    TradeInsuranceForm,
    TravelInsuranceForm,
    HealthInsuranceForm,
    FuneralInsuranceForm,
    MotorInsuranceTerms,
    HouseInsuranceTerms,
    FuneralInsuranceTerms,
    HealthInsuranceTerms,
    LifeInsuranceTerms,
    ReportClaim,
  },
  data() {
    return {
      changeNavbarColor: "menu-title-gray-white",
      isHovered: false,
      showDrawerClass: false,
    };
  },
  setup() {
    const typing = ref(null);

    onMounted(() => {
      new Typed(typing.value, {
        strings: [
          "tailored to your needs",
          "that secures your future",
          "for worry-free living",
        ],
        typeSpeed: 180,
        backSpeed: 60,
        backDelay: 1200,
        loop: true,
      });
    });

    return { typing };
  },
  beforeMount() {
    window.addEventListener("scroll", this.changeHeaderClass);
  },
  created() {
    //Set page title
    document.title = "Home | " + process.env.VUE_APP_TITLE;
  },
  methods: {
    scrollMeTo(refName) {
      this.changeHeaderClass();

      const element = this.$refs[refName] as any;
      const top = element.offsetTop;
      console.log(refName, element);

      window.scrollTo({
        top: top,
        left: 0,
        behavior: "smooth",
      });
    },
    changeHeaderClass() {
      if (window.pageYOffset > 0) {
        this.changeNavbarColor = "menu-title-gray-600";
      } else {
        this.changeNavbarColor = "menu-title-gray-white";
      }
    },
    addHoverClass() {
      this.isHovered = true;
    },
    removeHoverClass() {
      this.isHovered = false;
    },
    showDropdownClass() {
      this.isHovered = !this.isHovered;
    },
    dropdownClass() {
      this.isHovered = !this.isHovered;
    },
    toggleDrawer() {
      this.showDrawerClass = !this.showDrawerClass;
    },
  },
});
