import variables from "@/router/api";

const covers = [
  {
    id: variables.generateRandomNumber(),
    name: "Perils Protected",
    column1: null,
    column2: null,
    column3: null,
    column4: true,
    header: true,
  },
  {
    id: variables.generateRandomNumber(),
    name: "Death by any means",
    column1: true,
    column2: true,
    column3: true,
    column4: true,
    header: false,
  },
  {
    id: variables.generateRandomNumber(),
    name: "Benefits",
    column1: null,
    column2: null,
    column3: null,
    column4: true,
    header: true,
  },
  {
    id: variables.generateRandomNumber(),
    name: "No Medical Examination is required",
    column1: true,
    column2: true,
    column3: true,
    column4: true,
    header: false,
  },
  {
    id: variables.generateRandomNumber(),
    name: "Worldwide Coverage",
    column1: true,
    column2: true,
    column3: true,
    column4: true,
    header: false,
  },
  {
    id: variables.generateRandomNumber(),
    name: "Extensions",
    column1: null,
    column2: null,
    column3: null,
    column4: true,
    header: true,
  },
  {
    id: variables.generateRandomNumber(),
    name: "Funeral/Burial Expenses",
    column1: false,
    column2: true,
    column3: true,
    column4: true,
    header: false,
  },
  {
    id: variables.generateRandomNumber(),
    name: "Critical Illness Cover",
    column1: false,
    column2: true,
    column3: true,
    column4: true,
    header: false,
  },
  {
    id: variables.generateRandomNumber(),
    name: "Disability Cover",
    column1: false,
    column2: false,
    column3: true,
    column4: true,
    header: false,
  },
  {
    id: variables.generateRandomNumber(),
    name: "Spouse Cover",
    column1: false,
    column2: false,
    column3: false,
    column4: true,
    header: false,
  },
  {
    id: variables.generateRandomNumber(),
    name: "Exclusions",
    column1: null,
    column2: null,
    column3: null,
    column4: true,
    header: true,
  },
  {
    id: variables.generateRandomNumber(),
    name: "Suicide within the First One (1) year of arrangement of policy",
    column1: true,
    column2: true,
    column3: true,
    column4: true,
    header: false,
  },
  {
    id: variables.generateRandomNumber(),
    name: "War Risks & its allied perils",
    column1: true,
    column2: true,
    column3: true,
    column4: true,
    header: false,
  },
  {
    id: variables.generateRandomNumber(),
    name: "Warranties",
    column1: null,
    column2: null,
    column3: null,
    column4: true,
    header: true,
  },
  {
    id: variables.generateRandomNumber(),
    name: "Full declaration of medical conditions",
    column1: true,
    column2: true,
    column3: true,
    column4: true,
    header: false,
  },
  {
    id: variables.generateRandomNumber(),
    name: "Full declaration of social pass-times and habits",
    column1: true,
    column2: true,
    column3: true,
    column4: true,
    header: false,
  },
  {
    id: variables.generateRandomNumber(),
    name: "Submit for medical examinations (where the benefits is above certain limit), .e.g. Benefits amount beyond NGN10Million) will required medical examination",
    column1: false,
    column2: false,
    column3: false,
    column4: true,
    header: false,
  },
  {
    id: variables.generateRandomNumber(),
    name: "Complete Medical Questionnaire ",
    column1: false,
    column2: false,
    column3: false,
    column4: true,
    header: false,
  },
];

export default covers;
