
import { defineComponent } from "vue";

export default defineComponent({
  name: "house-insurance-terms",
  components: {},
  data() {
    return {
      url: "house-insurance-package-quote",
    };
  },
});
