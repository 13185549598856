
import { defineComponent } from "vue";
import HealthCovers from "@/views/frontend/constants/health-covers.js";

export default defineComponent({
  name: "health-insurance-terms",
  components: {},
  data() {
    return {
      HealthCovers: HealthCovers,
    };
  },
});
